import { Grid, CircularProgress, Button, Dialog, Stack } from "@mui/material";
import {
  CalendarTodayOutlined,
  MonetizationOnOutlined,
  PeopleOutline,
  PersonOutline,
  ReceiptOutlined,
  SportsOutlined,
} from "@mui/icons-material";
import { green } from "@mui/material/colors";

import React, { useEffect, useState, useCallback, useContext } from "react";

import {
  participantsUpdateRoute,
  tournamentSignUpsShowRoute,
  participantsDeleteRoute,
  baseDomain,
} from "network/api-routes";
import performRequest from "network/perform-request";
import { useSnackbar } from "notistack";

import AlertDialog from "components/common/AlertDialog";
import EditParticipantModal from "../Tournament/EditParticipantModal";
import ProfilesModal from "../Tournament/ProfilesModal";
import RevaText from "components/common/RevaText";
import UserContext from "context/UserContext";
import { useIntl } from "react-intl";

const ItemWithIcon = ({ label, value, valueColor, icon, footer }) => (
  <Grid item xs={12} container alignItems="flex-start" spacing={1}>
    <Grid item>{icon}</Grid>
    <Grid item xs>
      <RevaText variant="caption" sx={{ fontWeight: 500, color: "text.secondary" }}>
        {label}
      </RevaText>
      <RevaText sx={{ color: valueColor || "text.primary" }} variant="body1">
        {value}
      </RevaText>
      {footer && <div>{footer}</div>}
    </Grid>
  </Grid>
);

const EditSignUpModal = ({ signUpId, onSave, onParticipantUpdated }) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const [signUp, setSignUp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openProfilesModal, setOpenProfilesModal] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const fetchSignUp = useCallback(() => {
    setLoading(true);
    performRequest("POST", tournamentSignUpsShowRoute, { tournament_sign_up_id: signUpId }, true, user)
      .then((response) => {
        setSignUp(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [signUpId, user]);

  useEffect(() => {
    fetchSignUp();
  }, [fetchSignUp]);

  const togglePaid = () => {
    const participant = signUp.participant.data;
    const newStatus = signUp.status !== "waiting-payment" ? "waiting-payment" : "completed";

    setLoading(true);
    performRequest("POST", participantsUpdateRoute, { participant_id: participant.id, status: newStatus }, true, user)
      .then((response) => {
        enqueueSnackbar(
          intl.formatMessage({
            id: newStatus === "completed" ? "Inscripción marcada como pagada" : "Inscripción marcada como pendiente",
          }),
          {
            variant: "success",
          }
        );
        onSave();
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "Ocurrió un error al marcar la inscripción" }),
          {
            variant: "error",
          }
        );
        setLoading(false);
      });
  };

  const handleDelete = () => {
    performRequest("POST", participantsDeleteRoute, { participant_id: signUp.participant_id }, true, user)
      .then((response) => {
        enqueueSnackbar(intl.formatMessage({ id: "Participante eliminado con éxito" }), { variant: "success" });
        onSave();
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "Error al eliminar participante" }),
          {
            variant: "error",
          }
        );
      });
  };

  const openPaymentsLink = (id) => {
    const url = `${baseDomain}/admin/online-payments/${id}`;
    window.open(url, "_blank");
  };

  if (loading || !signUp) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ padding: 40 }}>
        <CircularProgress />
      </Grid>
    );
  }

  const paid = signUp.status !== "waiting-payment";
  const tournament = signUp.tournament.data;
  const participant = signUp.participant.data;
  const userData = signUp.user.data;

  return (
    <Stack sx={{ p: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RevaText id="Detalles de inscripción" variant="h4" />
        </Grid>

        <Grid item xs={12} md={6} container spacing={2}>
          <ItemWithIcon
            label={intl.formatMessage({ id: "Torneo" })}
            value={tournament.name}
            icon={<SportsOutlined />}
          />
          <ItemWithIcon
            label={intl.formatMessage({ id: "Participante" })}
            value={participant.display_name}
            icon={<PeopleOutline />}
            footer={
              <Button onClick={() => setOpenProfilesModal(true)} size="small">
                <RevaText sx={{ color: green[300], fontSize: 12 }}>Ver perfiles</RevaText>
              </Button>
            }
          />
          <ItemWithIcon
            label={intl.formatMessage({ id: "Fecha" })}
            value={intl.formatMessage(
              { id: "created.on" },
              {
                date: intl.formatDate(signUp.created_at, {
                  weekday: "short",
                  day: "2-digit",
                  month: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
              }
            )}
            icon={<CalendarTodayOutlined />}
          />
        </Grid>

        <Grid item xs={12} md={6} container spacing={2}>
          {!signUp.is_admin && (
            <ItemWithIcon
              label={intl.formatMessage({ id: "Creada por" })}
              value={userData.full_name}
              icon={<PersonOutline />}
              footer={
                <div>
                  <Button size="small" sx={{ padding: 0, minWidth: 0, mr: 1 }}>
                    <a href={`tel:${userData.cellphone_number}`}>
                      <RevaText id="Llamar" color="success" />
                    </a>
                  </Button>
                  <Button size="small" sx={{ padding: 0, minWidth: 0 }}>
                    <a
                      href={`https://wa.me/${userData.cellphone_number.replace("+", "")}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RevaText color="success">WhatsApp</RevaText>
                    </a>
                  </Button>
                </div>
              }
            />
          )}

          <ItemWithIcon
            label={intl.formatMessage({ id: "Tipo de pago" })}
            value={intl.formatMessage({ id: signUp.payment_id ? "Pago online" : "Efectivo / Otro" })}
            icon={<MonetizationOnOutlined />}
            footer={
              signUp.payment_id && (
                <Button onClick={() => openPaymentsLink(signUp.payment_id)} size="small">
                  {intl.formatMessage({ id: "Ver comprobante" })}
                </Button>
              )
            }
          />

          <ItemWithIcon
            label={intl.formatMessage({ id: "Estado de pago" })}
            value={intl.formatMessage({ id: paid ? "✔ Pagado" : "⚠ Pendiente" })}
            valueColor={paid ? "primary.main" : "secondary.main"}
            icon={<ReceiptOutlined />}
          />
        </Grid>
      </Grid>

      <Stack direction="row" sx={{ mt: 3, justifyContent: "space-between", gap: 4 }}>
        <Button color="error" onClick={() => setOpenDeleteAlert(true)}>
          {intl.formatMessage({ id: "Eliminar inscripción" })}
        </Button>
        {!signUp.payment_id && (
          <Button variant="outlined" color="inherit" onClick={togglePaid}>
            {intl.formatMessage({ id: paid ? "Marcar como pendiente de pago" : "Confirmar pago" })}
          </Button>
        )}
      </Stack>

      <AlertDialog
        hideButton
        isOpen={openDeleteAlert}
        onConfirm={() => {
          setOpenDeleteAlert(false);
          handleDelete();
        }}
        onCancel={() => setOpenDeleteAlert(false)}
        alertBody={intl.formatMessage({
          id: "¿Estás seguro de que quieres eliminar a este participante del torneo? Asegúrate de haberlo conversado previamente.",
        })}
        confirmButtonTitle={intl.formatMessage({ id: "Sí, estoy seguro" })}
        cancelButtonTitle={intl.formatMessage({ id: "Volver atrás" })}
      />

      <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)} fullWidth maxWidth="md">
        <EditParticipantModal
          participantId={signUp.participant_id}
          hideTournamentSignUp
          onSave={(newParticipant) => {
            onParticipantUpdated(newParticipant);
            setOpenEditModal(false);
            fetchSignUp();
          }}
        />
      </Dialog>

      <Dialog open={openProfilesModal} onClose={() => setOpenProfilesModal(false)} fullWidth maxWidth="md">
        <ProfilesModal
          participant={participant}
          editParticipant={() => {
            setOpenProfilesModal(false);
            setOpenEditModal(true);
          }}
        />
      </Dialog>
    </Stack>
  );
};

export default EditSignUpModal;
